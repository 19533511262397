document.addEventListener('turbo:load', function() {

  // trackers
  const tracker1 = document.querySelector('.home-1')
  const tracker2 = document.querySelector('.home-2')
  const tracker3 = document.querySelector('.home-3')

  // page elements
  const body = document.querySelector('body')
  const hero = document.querySelector('.hero')
  const claim3 = document.querySelector('.claim3')
  const midZone = document.querySelector('.mid-zone')
  const bg2 = document.querySelector('.static-bg-2')
  const bg3 = document.querySelector('.static-bg-3')
  const box1 = document.querySelector('.box-1')
  const box2 = document.querySelector('.box-2')

  // options
  const options = { root: body, threshold: 0.3 }

  // home 1
  const callback1 = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        bg2.classList.remove('active')
        box1.classList.add('shift-right')
        box2.classList.add('shift-left')
      }
    })
  }
  const options1 = { root: body, threshold: 0.75 }
  const observer1 = new IntersectionObserver(callback1, options1)
  if (tracker1) { observer1.observe(tracker1) }

  // home 2
  const callback2 = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        box1.classList.remove('shift-right')
        box2.classList.remove('shift-left')
        bg2.classList.add('active')
        hero.classList.remove('animate__fadeInUp')
      } else {
        hero.classList.add('animate__fadeInUp')
      }
    })
  }
  const observer2 = new IntersectionObserver(callback2, options)
  if (tracker2) { observer2.observe(tracker2) }

  // home 3
  const callback3 = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        bg2.classList.remove('active')
        bg3.classList.add('active')
        claim3.classList.add('animate__fadeInLeft')
        midZone.classList.add('active')
      } else {
        bg3.classList.remove('active')
        body.classList.remove('section-3')
        claim3.classList.remove('animate__fadeInLeft')
        midZone.classList.remove('active')
       }
    })
  }
  const observer3 = new IntersectionObserver(callback3, options)
  if (tracker3) { observer3.observe(tracker3) }

})

