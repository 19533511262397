document.addEventListener('turbo:load', function () {

  const body = document.querySelector('body')
  const options = { root: body, threshold: 0.01 }

  const buttonsZones = document.querySelectorAll('.js-buttons')

  buttonsZones.forEach((entry) => {
    const buttonCenter = entry.querySelector('.animated-button.center')
    const buttonLeft = entry.querySelector('.animated-button.left')
    const buttonRight = entry.querySelector('.animated-button.right')

    const callbackButtons = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (buttonCenter) { buttonCenter.classList.add('animate__fadeInUp') }
          if (buttonLeft) { buttonLeft.classList.add('animate__fadeInLeft') }
          if (buttonRight) { buttonRight.classList.add('animate__fadeInRight') }
        } else {
          if (buttonCenter) { buttonCenter.classList.remove('animate__fadeInUp') }
          if (buttonLeft) { buttonLeft.classList.remove('animate__fadeInLeft') }
          if (buttonRight) { buttonRight.classList.remove('animate__fadeInRight') }
        }
      })
    }
    new IntersectionObserver(callbackButtons, options).observe(entry)
  })

})
